export const I_JOGOSULTAK = {
  path: '/jogosultaks',
  fields: [
    { headerName: "Azonosito", field: 'id', width:75, type:'text',  model: '',readOnly: true, hideOnAdd: true },
    { headerName: "Vezeteknev", field: 'vezeteknev',  filter: true, width:200, type:'text', required: true, model: '' },
    { headerName: "Keresztnev", field: 'keresztnev',   filter: true, width: 200 , type:'text', required: true,model: ''},
    { headerName: "Cim", field: 'cim', width: 140, type:'text', required: true ,model: ''},
    { headerName: "Sirhely", field: 'sirhely',width:160, hide: true, type:'none', required: true ,model: ''},
    { headerName: "Sirhely", field: 'sirhely_parsed',width:160,model: '',type:'text' },
    { headerName: "Telefon", field: 'telefon', width: 140, type:'text', required: true,model: '' },
    { headerName: "Vallas", field: 'vallas', width:140, type:'text', required: true,model: '' },
    { headerName: "Egyhazkozseg", width:180,field: 'egyhazkozseg',model: '', type:'text' },
    { headerName: "Egyhaztag datum", field: 'egyhaztag_datum',width:180,filter: 'agDateColumnFilter', type:'text', required: true,model: '' },
    { headerName: "Miota jogosult", field: 'miota_tulajdonos',width:180,filter: 'agDateColumnFilter', type:'text', required: true,model: '' },
    { headerName: "Megjegyzes", field: 'megjegyzes',width:130, type:'text',model: '' },
  ]
}


export const I_ELHUNYTAK = {
  path: '/elhunytaks',
  fields: [
    { headerName: "Azonosito", field: 'id', width:75, type:'text',  model: '',readOnly: true, hideOnAdd: true },
    { headerName: "Vezeteknev", field: 'vezeteknev',  filter: true, width:200, type:'text', required: true, model: '' },
    { headerName: "Keresztnev", field: 'keresztnev',  filter: true, width: 200 , type:'text', required: true,model: ''},
    { headerName: "Szuletesi datum", field: 'szuletesi_datum',width:170 ,filter: 'agDateColumnFilter', type:'text',model: ''},
    { headerName: "Temetesi Datum", field: 'temetesi_datum',width:170 ,filter: 'agNumberColumnFilter', type:'text',model: ''},
    { headerName: "Sirhely", field: 'sirhely',width:160, hide: true, type:'text', required: true ,model: ''},
    { headerName: "Sirhely", field: 'sirhely_parsed',width:160,model: '',type:'none' },
    { headerName: "Foglalkozas", field: 'foglalkozas', width: 150, type:'text' ,model: '' },
    { headerName: "Egyhazkozseg", field: 'egyhazkozseg', type:'text',model: '' },
    { headerName: "Megjegyzes", field: 'megjegyzes',width:130, type:'text',model: '' },
  ]
}

export const I_JOGTUDOK = {
  path: '/orokosoks',
  fields: [
    { headerName: "Azonosito", field: 'id', width:75, type:'text',  model: '',readOnly: true, hideOnAdd: true },
    { headerName: "Vezeteknev", field: 'vezeteknev', filter: true, width:200, type:'text', required: true, model: '' },
    { headerName: "Keresztnev", field: 'keresztnev', filter: true, width: 200 , type:'text', required: true,model: ''},
    { headerName: "Cim", field: 'cim', width: 100,type:'text', model: ''  },
    { headerName: "Telefon", field: 'telefonszam', width: 100,type:'text' ,model: ''  },
    { headerName: "Vallas", field: 'vallas', width:130 ,type:'text',model: '' },
    { headerName: "Rokon neve", field: 'rokon_neve', width:200,type:'text', required: true ,model: ''  },
    { headerName: "Rokonsagi fok", field: 'rokonsagi_fok', width:150 ,type:'text', required: true ,model: '' },
    { headerName: "Sirhely", field: 'sirhely',width:160, hide: true, editable: false,type:'text', required: true ,model: ''  },
    { headerName: "Sirhely", field: 'sirhely_parsed',width:160, editable: false , type: 'none'},

  ]
}

export const I_SIRHELYEK = (instance) =>{
  return{
  path: '/sirhelies',
  fields: [
    { headerName: "Azonosito", field: 'id', width:75, type:'text',  model: '',readOnly: true, hideOnAdd: true },
    { headerName: "Sirhelytabla", filter: true, field: 'sirhelytabla', width:130,type:'text', required: true,model: '' },
    { headerName: "Sorszam",  filter: true, field: 'sorszam', width: 120 ,type:'text', required: true,model: ''},
    { headerName: "Jogosultak", field: 'jogosultak',width:130 ,type:'text', interface: '/jogosultaks',cellRenderer: 'btnCellRenderer', select: true,
      cellRendererParams: {setState :instance.setState, readOnly: true}, id: '',results: '', required: true ,model: []},
    { headerName: "Elhunytak",hideGrid:true,select: true, field: 'elhunytak',width:130 ,type:'text',interface: '/elhunytaks', id: '',results: '', cellRenderer: 'btnCellRenderer',
      cellRendererParams: {setState :instance.setState, readOnly: true}, required: true ,model: []},
    { headerName: "Jogutodok",hideGrid:true,select: true, field: 'orokosok',width:130 ,type:'text', interface: '/orokosoks', cellRenderer: 'btnCellRenderer',
      cellRendererParams: {setState :instance.setState, readOnly: true}, id: '',results: '', required: true ,model: []},
    { headerName: "Lejarati ideje", field: 'lejarasi_ideje', filter: 'agNumberColumnFilter',width:170,type:'text', required: true,model: '' },
    { headerName: "Viz szemet datum", field: 'viz_szemet_datum',width:170 , filter: 'agDateColumnFilter',type:'text', required: true,model: ''},
    { headerName: "Viz szemet dij", field: 'viz_szemet_dij',width:170 ,type:'text', required: true,model: ''},
    { headerName: "Nyilvantartasi szam", field: 'nyilvantaratas_szama',type:'text',model: '' },
    { headerName: "Elado", field: 'elado', width: 90,type:'text',model: '' },
    { headerName: "Jogszerzes ideje", field: 'jogszerzes_ideje',filter: 'agDateColumnFilter', width: 170,type:'text', required: true,model: '' },
    { headerName: "Dij", field: 'dij', width:100,type:'text', required: true,model: '' },
    { headerName: "Leiras", field: 'leiras',type:'text', required: true,model: '' },
    { headerName: "Zart", field: 'zart',width:100,type:'text',model: '' },
    { headerName: "Muemlek", field: 'muemlek',width:130 ,type:'text', editable: true ,model: ''},
    { headerName: "Meret", field: 'meret',width:130 ,type:'text', editable: true ,model: ''},
    { headerName: "Hivatkozasok", field: 'hivatkozasok',width:160 ,type:'text', editable: true ,model: ''},
    { headerName: "Megjegyzesek", field: 'megjegyzesek',width:160 ,type:'text', editable: true ,model: ''}
  ]}
}

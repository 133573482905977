import Vue from 'vue';

import {faCopy} from "@fortawesome/free-solid-svg-icons";
import {Clipboard_CopyTo} from "@/methods/state";

export default Vue.extend({
  name:'CopyRenderer',
  template: `
        <span>
              <b-button variant="outline-primary" size="sm" @click="()=>$copyText(cellValue)">
                <font-awesome-icon :icon="faCopy"/>
              </b-button>
          </span>
    `,
  data: function () {
    return {
      cellValue: null,
      faCopy,
    };
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    this.cellValue = this.getValueToDisplay(this.params);
  },
  methods: {
    getValueToDisplay(params) {
      return params.valueFormatted ? params.valueFormatted : params.value;
    },
    // gets called whenever the user gets the cell to refresh
    refresh(params) {
      // set value into cell again
      this.cellValue = this.getValueToDisplay(params);
    },
    Clipboard_CopyTo: Clipboard_CopyTo
  },
});

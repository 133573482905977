<template>
      <b-container class="position-absolute bg-white w-100" style="z-index: 20000; top: 0; left:0; right: 0; margin: 0 auto; overflow: scroll" id="toPrint">
        <div id="pdf">

        </div>

      </b-container>
</template>

<script>


import {Grid} from "../../mixins/Grid";
import XLSX from 'xlsx';
import {saveAs} from 'file-saver';

export default {
  name: "Print",
  mixins: [Grid],
  props: {
    items: {
      type: Array,
      default: () => []
    },
    setParent: {
      type: Function,
      default: function () {
      }
    },
    interfaces: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: 'Sír szám',
            // Variant applies to the whole column, including the header and footer
          }
        ],
      body: {},
      output: null,
      elements: [],
      headers: [
        {
          headerName: 'Nr',
          field: 'Nr'
        },
        {
          headerName: 'Sir szam',
          field: 'sirhelytabla'
        },
        {
          headerName: 'Lejarasi ideje',
          field: 'lejarasi_ideje'
        },
        {
          headerName: 'Jogosult neve',
          field: 'jogosultak',
          width: 500
        }
      ]
    }
  },
  beforeMount() {

    this.elementss = this.items.map((e) => {
      const d = {}
      d['Sír szám'] = e.sirhelytabla+ '-'+e.sorszam
      d['Lejárati ideje'] = e.lejarasi_ideje
      d['Jogosult neve'] = e.jogosultak.map(el => ([`${el.vezeteknev} ${el.keresztnev}`].toString())).toString()
      return d
    });
  },
  async mounted() {
   const elements = [...this.elementss]
    this.elements = elements
    this.elements.sort((a, b) => (a['Lejárati ideje'] > b['Lejárati ideje']) ? 1 : -1)
    this.elements = this.elements.map((e,index)=> {
      return {Nr: index +1 ,...e}
    })

    const wb = XLSX.utils.book_new();
    const date = new Date();
    wb.Props = {
      Title: "Listazas_"+ date.getFullYear() + date.getMonth() + date.getDay(),
      Subject: "Listazas",
      Author: "Reformatus Temeto",
    };

    wb.SheetNames.push("Lejart Sirok");
    wb.Sheets["Lejart Sirok"] = XLSX.utils.json_to_sheet(this.elements);
    const wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});

    function s2ab(s) {

      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), 'Lejart_sirok.xlsx');

    this.setParent('print', false)
  },
  methods: {

  }
}
</script>

<style lang="scss">


   .table td:first-child {
      margin-left: 10px;
    }
</style>

<template>
  <div class="actionbar my-2 mx-4 d-flex justify-content-between">
    <div>
      <b-button variant="success rounded-0 mr-3" size="sm" @click="addModal">
        <font-awesome-icon :icon="faPlus" class="mr-2"/>
        Hozáadás
      </b-button>
      <b-button variant="success rounded-0 mr-3" size="sm" @click="printData" v-if="modal.interface.path === '/sirhelies'">
        <font-awesome-icon :icon="faPrint" class="mr-2" />
        Listazas
      </b-button>
    </div>
    <AddEditModal :loadData="loadData" :title="modal.title" :data="modal.data" :interface="modal.interface" :type="type" v-if="show" :set-state="setState" :set-parent="setShow"/>
    <ViewModal :loadData="loadData" :title="modal.title" :data="modal.data" :interface="modal.interface" :type="type" v-if="view" :set-state="setState" :set-parent="setShow"/>
    <Print :set-parent="setState" :interfaces="modal.interface" :items="items" v-if="print"/>
  </div>
</template>

<script>
import AddEditModal from "../Modal/AddEditModal";
import {setState} from "../../methods/state";
import { faPrint,faPlus } from '@fortawesome/free-solid-svg-icons'
import Print from "../Modal/Print";
import ViewModal from "../Modal/ViewModal";

export default {
  name: "Actions",
  components: {ViewModal, Print, AddEditModal},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    view: {
      type: Boolean,
      default: false
    },
    setShow: {
      type: Function,
      default: function (){}
    },
    loadData: {
      type: Function,
      default: function (){}
    },
    modal: {
      type: Object,
      default: () => {}
    },
    gridApi: {
      type: Object,
      default: () => {}
    },
  },
  data(){
    return{
      type: 'put',
      faPlus,
      faPrint,
      print: false,
      items:null,
    }
  },
  watch: {
    type(e){
      console.log('From actions: ',e)
    }
  },
  methods: {
    printData(){
      const items = this.gridApi.getSelectedRows()
      if(items.length){
        this.items = items
        this.print = true
      }else{
        this.$toasted.show('Nyomtatáshoz ki kell választani az adatokat', {
          duration: 2000,
        })
      }
    },
    setState,
    addModal(){
      this.type = 'post';
      this.setShow('show',true)
      this.setShow('data',{})
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <b-modal :visible="true" size="lg" id="addeditmodal" class="fade" :title="title + ' ' + titleMessage">

    <form @submit="addOrdEdit" class="row">

      <b-col :md="6" v-for="input in model" :key="input.name" class="my-2 w-100 fixed-col h-100" >
        <div v-if="input.type !== 'none' && input.field!== 'sirhely'" class="h-100">
          <small class="text-muted">
            {{input.headerName}}
          </small>
          <div v-if="input.select && input.field !== 'sirhely'" class="w-100 h-100 border p-2`">
            <div v-for="inp in input.model" :key="inp.id"   class="px-2 py-3 my-2 d-flex justify-content-between">
              <div>
                <small >
                  Nev:
                </small>
                <span>
                {{inp.vezeteknev}} {{inp.keresztnev}}
                </span>
              </div>
              <b-button  size="sm" @click="deleteData(input,inp)">
                <font-awesome-icon :icon="faTrashAlt"/>
              </b-button>
           </div>
            <div class="h-100">
              <hr/>
              <div class="w-100 d-flex align-items-center mb-2">
                <Autocomplete :model="input.interface" :setData="setData" :obj="input"  />
              </div>
            </div>
          </div>
          <label v-else class="w-100 d-flex">
            <input :id="input.field" :placeholder="input.headerName" class="form-control" :required="input.required" :disabled="input.readOnly"  v-model="input.model" :type="input.type" />
          </label>
        </div>
      </b-col>
      <b-col md="12">
        <b-button variant="success" block type="submit" >
          <font-awesome-icon :icon="type === 'post' ? faPlus : faEdit"/> {{actionMessage}}
        </b-button>
      </b-col>
      <input id="upload_id" :value="upload_id" type="text" style="position:absolute;top: -2000vh"  readonly />
    </form>
    <template slot="modal-footer" class="d-flex justify-content-between">
      <b-button variant="danger" @click="deleteRow" v-if="type === 'put'">
        <font-awesome-icon :icon="faTrashAlt" class="mr-2"/>Törlés
      </b-button>
      <b-button variant="success" @click="$bvModal.hide('addeditmodal')" >
        Bezárás
      </b-button>
    </template>
  </b-modal>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import {API_URL} from "../../../config/dev";
import { faEdit,faTrashAlt,faPlus,faSearch,faPaste } from '@fortawesome/free-solid-svg-icons'
import {Clipboard_CopyTo} from "@/methods/state";
import Autocomplete from "@/components/Data/Autocomplete";

export default {
  name: "AddEditModal",
  components: {Autocomplete},
  props: {
    title: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "post"
    },
    interface: {
      type: Object,
      default: () => {}
    },
    data:{
      type: Object,
      default: {
        'jogosultak': [],
        'elhunytak': [],
        'orokosok': []
      }
    },
    setParent:{
      type: Function,
      default: function (){}
    },
    loadData: {
      type: Function,
      default: function (){}
    },
    setState:{
      type: Function,
      default: function (){}
    },
  },
  watch: {
    model: {
      handler: function (e){
        console.log(e)
      },
      deep: true
    }
  },
  computed: {
    model(){
      let elements = [...this.interface.fields]
      if(this.data){
        elements = elements.map(e=>{
          e.model = this.data[e.field]
          return e
        })
      }
      return elements
    }
  },
  data(){
    return{
      titleMessage: "",
      actionMessage: "",
      faTrashAlt,
      faEdit,
      upload_id: '',
      faPlus,
      faPaste,
      faSearch,
      searchVezeteknev: '',
      searchKeresztnev: ''
    }
  },
  beforeMount() {
    this.titleMessage = this.type === 'put' ? 'szerkesztése' : 'hozzáadása'
    this.actionMessage = this.type === 'put' ? 'Mentés' : 'Hozzáadás'
  },
  mounted() {
    this.$root.$on('bv::modal::hide', () => {
      this.setParent('show',false)
      this.setState('type','put')
    })
    console.log(this.data)
  },
  methods: {
    async pasteData(id){
      navigator.clipboard.readText().then(text => document.getElementById(id).value = text );
    },
    async deleteData(obj,elem){
      obj.model = obj.model.filter(e=>e.id !== elem.id)
    },
    setData(obj,elem){
      console.log(obj,elem)
      if(!Array.isArray(obj.model)){
        obj.model = []
      }
      obj.model.push(elem)
    },
    async getData(obj,path,id){
      let instance = {
        data: []
      }
      if(id){
        try{
          const query = `${path}/${id}`
          instance = await axios.get(API_URL+query)
          obj.model = [...obj.model, ...[instance.data]]
          this.$toasted.show(`${instance.data.vezeteknev} ${instance.data.keresztnev} hozzaadva`, {
            duration: 2000,
          })
        }catch (e){
          this.$toasted.show('Nincs talalat az alabbi azonositora : ' + id, {
            duration: 2000,
            type: 'error'
          })
        }
      }else{
        this.$toasted.show('Kerem irjon be egy azopnosito szamot', {
          duration: 2000,
          type: 'error'
        })
      }
      obj.id =''

    },
    async addOrdEdit(e){
      e.preventDefault();
      const data = {}
      this.model.forEach(e=>{
        if(Array.isArray(e.model)){
          e.model = e.model.map(el => el.id)
        }
        data[e.field] = e.model
        if(data[e.field] === 'sirhely')
          data[e.field] = [e.model]
      })
      let instance = ''
      try{
        if(this.type === 'put'){
          instance = await axios.put(API_URL+this.interface.path + '/'+ this.data.id, data)
        }else{
          instance = await axios.post(API_URL+this.interface.path, data)
        }
        this.upload_id = instance.data.id

        this.$toasted.show('Sikeres mentes', {
          duration: 2000,
          variant: 'success'
        })
      }catch (e){
        this.$toasted.show('A mentes sikerltelen probalja meg ujra', {
          duration: 2000,
          type: 'error'
        })
      }
      this.$bvModal.hide('addeditmodal')
      this.loadData(instance ,this.type === 'put' ? 'edit' : 'add')
    },
    Clipboard_CopyTo:Clipboard_CopyTo,
    async deleteRow(){
      try{
        await axios.delete(API_URL+this.interface.path + '/'+ this.data.id)
        this.$toasted.show('Sikeres törlés', {
          duration: 2000,
          variant: 'success'
        })
      }catch (e){
        this.$toasted.show('A törlés sikerltelen probalja meg újra', {
          duration: 2000,
          type: 'error'
        })
        console.log(e)
      }
      this.$bvModal.hide('addeditmodal')
      this.loadData(this.data.id,'delete')
    }
  }
}
</script>

<style scoped>
.fixed-col{
  min-height:auto;
}
</style>

import {AgGridVue} from "@ag-grid-community/vue";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css'
import ButtonRenderer from "../components/Grid/ButtonRenderer";
import {setState} from "../methods/state";

import { ModuleRegistry } from '@ag-grid-community/core';
import { CsvExportModule } from "@ag-grid-community/csv-export";
import {AG_GRID_LOCALE_HU} from "../../data/hu";
import CopyRenderer from "@/components/Grid/CopyRenderer";

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  CsvExportModule,
])

export const Grid = {

  data() {
    return {
      columnDefs: null,
      rowData: null,
      modules: null,
      gridOptions: null,
      defaultColDef: null,
      frameworkComponents: null,
      data: {jogosultak: [],elhunytak: [], orokosok: []},
      show: false,
      view: false,
      columnApi: null,
      filteredData: [],
      gridApi: null,
      AG_GRID_LOCALE_HU
    }
  },
  components: {
    AgGridVue
  },
  created() {
    const token = localStorage.getItem('token')
    if (token) {
      this.axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    }
  },
  async beforeMount() {
    this.columnDefs = [
      {
        field: "",
        pinned: 'left',
        pinLocked: true,
        width: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      {
        field: '',
        width: 150,
        pinned: 'left',
        pinLocked: true,
        filter: false,
        sortable: false,
        cellRenderer: 'btnCellRenderer',
        cellRendererParams: {setState :this.setState}
      },
    ];
    this.frameworkComponents = {
      btnCellRenderer: ButtonRenderer,
      CopyRenderer: CopyRenderer
    }
    this.gridOptions = {}
    this.modules = [ClientSideRowModelModule];
    this.defaultColDef = {
      editable: false,
      filter: 'agTextColumnFilter',
      sortable: true,
      filterParams: {
        buttons: ['reset'],
      },
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
  methods: {
    setState
  }
}

<template>
  <b-modal :visible="true" id="viewModal" size="lg" class="fade" no-close-on-backdrop  :title="title + ' ' + titleMessage" no-close-on-esc>
    <template slot="modal-header">
      <div class="v-100 text-right flex-grow-1">
        <font-awesome-icon :icon="faTimes" @click="setParent('view', false)"/>
      </div>
    </template>
    <b-container>
      <b-row>
        <b-col :md="4" v-for="input in model" :key="input.name" class="mt-2 px-1 w-100 fixed-col" >
          <div class="border p-2 h-100" v-if="input.type !== 'none' ">
            <h6 class="text-dark">
              {{ input.headerName }}
            </h6>
            <div v-if="typeof input.model === 'boolean'">
              <p class="font-wight-bold">
                {{ input.model ? 'Igen' : "Nem" }}
              </p>
            </div>
            <div class="d-flex justify-content-between" v-if="typeof input.model === 'string' || typeof input.model === 'number' || input.model ===''">
              <p class="font-wight-bold">
                {{ input.model }}
              </p>
            </div>
            <div v-if="Array.isArray(input.model) && input.field !== 'sirhely'">
              <hr/>
              <div v-for="(mod,index) in input.model" :key="`${mod.vezeteknev}${index}`">
                <div>
                  <small class="font-wight-bold">
                    Nev:
                  </small>
                  <p class="my-0 font-wight-bold">
                    {{ mod.vezeteknev}}
                    {{ mod.keresztnev}}
                  </p>
                </div>
                <div v-if="input.field === 'elhunytak'">
                  <small class="font-wight-bold">Temetési dátum: </small>
                  <p class="font-wight-bold">{{ mod.temetesi_datum}}</p>
                </div>
                <hr/>
              </div>
            </div>
            <div v-if="Array.isArray(input.model) && input.field === 'sirhely'">
              <div v-for="(mod,index) in input.model" :key="`${mod.vezeteknev}${index}`">
                <div>
                  <p class="font-wight-bold">
                    {{ mod.sirhelytabla}}-{{ mod.sorszam}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <template slot="modal-footer" class="d-flex justify-content-between">
      <b-button variant="success" @click="setParent('view', false)" >
        Bezárás
      </b-button>
    </template>
  </b-modal>
</template>

<script>

import { faEdit,faTrashAlt,faPlus,faTimes,faCopy } from '@fortawesome/free-solid-svg-icons'
import {Clipboard_CopyTo} from "@/methods/state";

export default {
  name: "ViewModal",
  props: {
    title: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "post"
    },
    interface: {
      type: Object,
      default: () => {}
    },
    data:{
      type: Object,
      default: () => null
    },
    setParent:{
      type: Function,
      default: function (){}
    },
    loadData: {
      type: Function,
      default: function (){}
    },
    setState:{
      type: Function,
      default: function (){}
    },
  },
  computed: {
    model(){
      let elements = [...this.interface.fields]
      if(this.data){
        elements = elements.map(e=>{
          e.readOnly = false
          e.model =  this.data[e.field]
          return e
        })
      }
      return elements
    }
  },
  data(){
    return{
      titleMessage: "",
      actionMessage: "",
      faTrashAlt,
      faEdit,
      faPlus,
      faTimes,
      faCopy
    }
  },
  mounted() {
  },
  methods: {
    Clipboard_CopyTo:Clipboard_CopyTo,
  }
}
</script>

<style scoped>
  .fixed-col{
    min-height:65px;
  }
  p{
    margin-bottom: 0;
  }
</style>

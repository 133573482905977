import Vue from 'vue';

import {faEdit,faEye} from "@fortawesome/free-solid-svg-icons";

export default Vue.extend({
  name:'ButtonRenderer',
  template: `
        <span>
              <b-button variant="outline-primary" v-if="!params.readOnly" size="sm" @click="buttonClicked()">
                <font-awesome-icon :icon="faEdit"/>
              </b-button>
              <b-button variant="outline-primary mr-4" size="sm" @click="button2Clicked()">
                <font-awesome-icon :icon="faEye"/>
              </b-button>
          </span>
    `,
  data: function () {
    return {
      cellValue: null,
      faEdit,
      faEye
    };
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    this.cellValue = this.getValueToDisplay(this.params);
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh(params) {
      // set value into cell again
      this.cellValue = this.getValueToDisplay(params);
    },

    buttonClicked() {
      this.params.setState('data',this.params.data)
      this.params.setState('show',true)
    },

    button2Clicked() {
      this.params.setState('data',this.params.data)
      this.params.setState('view',true)
    },

    getValueToDisplay(params) {
      return params.valueFormatted ? params.valueFormatted : params.value;
    },
  },
});

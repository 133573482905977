<template>
  <div class="flex">
    <div class="col-md-12 position-relative d-flex justify-content-between">
       <div class="d-flex">
         <input v-model="vezeteknev" placeholder="Vezeteknev" class="form-control mr-1">
         <input v-model="keresztnev" placeholder="Keresztnev" class="form-control mr-1">
       </div>
      <b-button variant="success" @click="filterData" >
        <font-awesome-icon :icon="faSearch"/>
      </b-button>
       </div>
      <div class="card mx-3 mt-2">
        <div class="card-body">
          <small class="d-block text-center" v-if="results.length === 0">
            Keresesi eredmenyek
          </small>
          <div v-else style="overflow-y: auto;max-height: 300px" >
            <div v-for="result in results" :key="result.id" class="d-flex justify-content-between my-2">
              <p>
                {{result.vezeteknev}}  {{result.keresztnev}}
              </p>
              <b-button variant="success" size="sm" class="mr-1" @click="()=>setData(obj,result)">
                <font-awesome-icon :icon="faPlus"/>
              </b-button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import {faSearch,faPlus} from '@fortawesome/free-solid-svg-icons'
export default {
  name: "Autocomplete",
  props: {
    model: {
      type: String,
      default: ''
    },
    obj: {
      type: Object,
      default: () => {}
    },
    setData: {
      type: Function,
      default: function (){}
    }
  },
  data(){
    return{
      vezeteknev: '',
      keresztnev: '',
      faSearch,
      faPlus,
      results: []
    }
  },
  methods: {
    async filterData(){
      const first = `vezeteknev=${this.vezeteknev}`
      const second = `keresztnev=${this.keresztnev}`
      const query = `${this.model}?` + (this.vezeteknev ? this.keresztnev ? first + '&' + second : first : this.keresztnev ? this.vezeteknev ? second + '&' + first : second : '')
      const {data} = await this.axios.get(query + '&_limit=-1')
      this.results = data
    },
    reset(){
      this.filteredData = this.rowData
      this.model = {
        vezeteknev: '',
        keresztnev: ''
      }
    },
  }
}
</script>

<style scoped>

</style>